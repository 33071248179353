export class BaseAPI {
    private baseUrl = "/api/";    
    // eslint-disable-next-line
    private async callAPI(url: string, request: RequestInit) {
        const response: Response = await fetch(this.baseUrl + url, request);
        if (response.ok) {
            switch (response.status) {
                case 201: // Created
                    return await response.headers.get("location");

                case 204: // No Content
                    break;

                default:
                    // 200 OK
                    // eslint-disable-next-line
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.includes("json")) {
                        return await response.json();
                    } else if (contentType && contentType.includes("audio/mp3")) {
                        return await response.blob();
                    } else {
                        return await response.text();
                    }
            }
        }
        // else {
        //     switch (response.status) {
        //         case 304: // Not Modified
        //             return;

        //         case 401: // Unauthorized
        //             // this.router.logout();
        //             return;

        //         case 409:
        //             const conflictError = await response.json();
        //             Object.assign(response, conflictError);
        //             throw response;

        //         default:
        //             // 400+ 500+
        //             const contentType = response.headers.get("content-type");
        //             const error: object | string = contentType && contentType.includes("json") ? await response.json() : await response.text();
        //             if (!error) {
        //                 throw response.statusText;
        //             }
        //             throw error;
        //     }
        // }
    }

    private getHeaders(headersParam?: Headers): Headers {
        const headers: Headers = new Headers();
        headers.set("Accept", "application/json");
        headers.set("Content-type", "application/json");

        const authToken = window.sessionStorage.getItem("authToken");
        if (authToken) {
            headers.set("Authorization", "Bearer " + authToken);
        }

        if (!headersParam) {
            return headers;
        }

        headersParam.forEach((val: string, key: string) => {
            headers.set(key, val);
        });

        return headers;
    }

    public post(url: string, data: string, headers?: Headers) {
        const request = {
            body: data,
            headers: this.getHeaders(headers),
            method: "post",
        };
        return this.callAPI(url, request);
    }

    public put(url: string, data?: string, headers?: Headers) {
        const request = {
            body: data,
            headers: this.getHeaders(headers),
            method: "put",
        };
        return this.callAPI(url, request);
    }    

    public get(url: string, headers?: Headers) {
        const request = {
            headers: this.getHeaders(headers),
            method: "get",
        };
        return this.callAPI(url, request);
    }    
}