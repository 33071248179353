export enum ResponseStatus {
    error,
    ok,
}

export class ResponseDO<T> {
    public status: ResponseStatus;
    public data: T | null;
    public errorMessage: string | null;

    constructor(status: ResponseStatus, data?: T, errorMessage?: string) {
        this.status = status;
        this.data = data || null;
        this.errorMessage = errorMessage || null;
    }
}
