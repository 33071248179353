import { RsvpDO, toRsvpDO } from "@/model/rsvp-do";
import { BaseAPI } from "./base-api";
import moment from "moment";
import { toRsvpListDO } from "@/model/rsvp-list-do";

export class RsvpAPI extends BaseAPI {
    public updateRsvp(rsvp: RsvpDO) {
        this.put(`rsvp/${rsvp.id}`, JSON.stringify(rsvp));
    }

    public createRsvp(rsvp: RsvpDO) {
        this.post("rsvp", JSON.stringify(rsvp));
    }

    public async getRsvp(id: string) {
        const rsvpJson = await this.get(`rsvp/${id}`);
        return toRsvpDO(rsvpJson);
    }

    public async getRsvps(
        name: string | null,
        attending: boolean | null,
        maxAllowedGuests: number | null,
        dateViewed: Date | null,
        orderBy: string,
        desc: boolean,
        currentPage: number,
    ) {
        let uri = `rsvp?orderBy=${orderBy}&desc=${desc}&currentPage=${currentPage}`;
        if (name) {
            uri = `${uri}&name=${name}`;
        }
        if (attending !== null) {
            uri = `${uri}&attending=${attending}`;
        }
        if (maxAllowedGuests) {
            uri = `${uri}&maxAllowedGuests=${maxAllowedGuests}`;
        }
        if (dateViewed) {
            uri = `${uri}&dateViewed=${moment(dateViewed).format("L")}`;
        }

        const rsvpListJson = await this.get(uri);
        return toRsvpListDO(rsvpListJson);
    }
    
    // public updateRsvp() {
    // }
}
