import { ResponseStatus, ResponseDO } from "@model/response-do";
import { RsvpDO } from "@/model/rsvp-do";
import { RsvpAPI } from "@api/rsvp-api";
import { RsvpListDO } from "@/model/rsvp-list-do";
export class RsvpController {
    private api: RsvpAPI;

    constructor(api: RsvpAPI) {
        this.api = api;
    }

    public async updateRsvp(rsvp: RsvpDO): Promise<ResponseDO<RsvpDO | null>> {
        try {
            this.api.updateRsvp(rsvp);
            return new ResponseDO<RsvpDO>(ResponseStatus.ok, undefined);
        } catch (e) {
            return new ResponseDO(ResponseStatus.error, null, "Error updating RSVP");
        }
    }

    public async createRsvp(rsvp: RsvpDO): Promise<ResponseDO<RsvpDO | null>> {
        try {
            this.api.createRsvp(rsvp);
            return new ResponseDO<RsvpDO>(ResponseStatus.ok, undefined);
        } catch (e) {
            return new ResponseDO(ResponseStatus.error, null, "Error creating RSVP");
        }
    }

    public async getRsvp(rsvp: RsvpDO, rsvpId?: string): Promise<ResponseDO<RsvpDO | null>> {
        const id = rsvpId || rsvp.id;
        const response: RsvpDO = await this.api.getRsvp(id);
        try {
            return new ResponseDO<RsvpDO>(ResponseStatus.ok, response);
        } catch (e) {
            return new ResponseDO(ResponseStatus.error, null, "Error retrieving RSVP");
        }
    }

    public async getRsvps(
        name: string | null,
        attending: boolean | null,
        maxAllowedGuests: number | null,
        dateViewed: Date | null,
        orderBy: string,
        desc: boolean,
        currentPage: number,
    ) {
        try {
            const response: RsvpListDO = await this.api.getRsvps(name, attending, maxAllowedGuests, dateViewed, orderBy, desc, currentPage);
            return new ResponseDO<RsvpListDO>(ResponseStatus.ok, response);
        } catch (e) {
            return new ResponseDO(ResponseStatus.error, null, "Error retrieving RSVPs");
        }
    }
}
