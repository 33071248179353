import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import { RsvpController } from "@/controllers/rsvp-controller";
import { RsvpAPI } from "@/api/rsvp-api";
import { RsvpDO } from "@/model/rsvp-do";

const rsvpCtrl = new RsvpController(new RsvpAPI());
const routes: Array<RouteRecordRaw> = [
    {
        meta: { label: "home" },
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        meta: { label: "about" },
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@views/AboutView.vue"),
    },
    {
        meta: { label: "details" },
        path: "/details",
        name: "details",
        component: () => import("@views/DetailsView.vue"),
    },
    {
        meta: { label: "admin" },
        path: "/admin",
        name: "admin",
        component: () => import("@views/AdminView.vue"),
    },
    {
        meta: { label: "rsvp" },
        path: "/rsvp",
        name: "rsvp",
        component: () => import("@/views/RsvpView.vue"),
    },
    {
        meta: { label: "rsvp" },
        component: () => import("@/views/RsvpFormView.vue"),
        name: "rsvpForm",
        path: "/rsvp/:id",
        beforeEnter: async (route, from, next) => {
            const response = await rsvpCtrl.getRsvp(new RsvpDO(), route.params.id as string);
            if (response.data?.id === undefined) {
                next("rsvp");
            } else {
                next();
            }
        },
    },
    {
        meta: { label: "rsvp" },
        name: "rsvpSuccess",
        path: "/success",
        props: (route) => ({ accept: route.params.accept, id: route.params.id }),
        component: () => import("@/views/RsvpSuccessView.vue"),
        beforeEnter: async (route, from, next) => {
            // dont allow refreshing at the success screen
            if (route.params.accept && route.params.id) {
                next();
            } else {
                next("rsvp");
            }
        },
    },
    {
        meta: { label: "admin" },
        path: "/admin/print",
        name: "printQr",
        component: () => import("@views/PrintQrView.vue"),
    },
    {
        meta: { label: "registry" },
        path: "/registry",
        name: "registry",
        component: () => import("@views/RegistryView.vue"),
    },
    {
        meta: { label: "admin" },
        path: "/login",
        name: "admin-login",
        component: () => import("@views/AdminLoginView.vue"),
    },
    {
        meta: { label: "admin" },
        path: "/logout",
        name: "admin-logout",
        redirect: () => {
            // the function receives the target route as the argument
            // a relative location doesn't start with `/`
            // or { path: 'profile'}
            window.sessionStorage.removeItem("authToken");
            return "login";
        },
    },
    {
        path: "/sample",
        name: "sample",
        component: () => import("@views/SampleView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// global routing rules (fired before every route)
router.beforeEach(async (to, from, next) => {
    const currentPath = to.fullPath;
    if (currentPath.startsWith("/admin")) {
        // store.state.me.jwt is a persistant value (see index.ts)
        const jwt = window.sessionStorage.getItem("authToken");
        if (jwt) {
            next();
            return;
        }
        next("/login");
    }

    // if (auth.isTokenValid(jwt) || skipAuth) {
    //     // this conditional will be triggered when any page is refreshed
    //     if (!store.state.me.loggedIn && !skipAuth) {
    //         meStore.setJWT(jwt, auth.parseToken(jwt));

    //         // getting me data on refresh
    //         const me = await meCtrl.getMe(messageCtrl, radioCtrl, deviceCtrl);
    //         if (me.status === ResponseStatus.ok && me.data) {
    //             meStore.setMe(me.data);
    //         }
    //     }
    //     next();
    //     return;
    // }

    // meStore.clearLoginInfo();
    // next("/login");
    next();
});

export default router;
