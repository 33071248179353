import { BaseDO } from "@model/base-do";

export class RsvpDO {
    public id!: string;
    public name!: string;
    public maxAllowedGuests: number| null = null;
    public attending: boolean | null = null;
    public dateViewed!: Date;
}

export function toRsvpDO(rsvpJson: BaseDO): RsvpDO {
    const rsvpDO = new RsvpDO();
    if (rsvpJson) {
        Object.assign(rsvpDO, rsvpJson);        
    }
    return rsvpDO;
}