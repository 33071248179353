export interface IPagedDO {
    [key: string]: string | boolean | number | number[] | object[] | null;
}
// export interface IpagedObject extends PagedDO {}
export class PagedDO {
    // signature
    [key: string]: string | boolean | number | number[] | object[] | null;
    public startIndex!: number;
    public endIndex!: number;
    public totalItems!: number;
    public pageSize!: number;
    public currentPage!: number;
    public startPage!: number;
    public endPage!: number;
    public totalPages!: number;
    public pages!: number[];
    public hasNextPage!: boolean;
    public hasPreviousPage!: boolean;
}