import { toRsvpDO, RsvpDO } from '@/model/rsvp-do';
import { PagedDO } from "@model/paged-do";
import { BaseDO } from '@model/base-do';

export class RsvpListDO extends PagedDO {
    public rsvps!: RsvpDO[];
}

export function toRsvpListDO(rsvpListJson: BaseDO): RsvpListDO {
    const rsvpListDO = new RsvpListDO();
    if (rsvpListJson) {
        Object.assign(rsvpListDO, rsvpListJson);
        rsvpListDO.rsvps.forEach((rsvp: RsvpDO, index: number) => {
            rsvpListDO.rsvps[index] = toRsvpDO((rsvpListDO.rsvps[index] as unknown) as BaseDO);
        });
    }

    return rsvpListDO;
}